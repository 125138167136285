<template>
  <!--begin::Post-->
  <div class="post d-flex flex-column-fluid" id="kt_post">
    <!--begin::Container-->
    <div id="kt_content_container" class="container-xxl">
      <!--begin::Card-->
      <div class="card">
        <!--begin::Card body-->
        <div class="card-body">
          <div v-if="message" class="alert alert-info mb-10" role="alert">
            {{ message }}
          </div>
          <Form
            @submit="handleSubmit"
            :validation-schema="schema"
            class="form w-100"
            novalidate="novalidate"
            id="kt_sign_in_form"
          >
            <div class="mb-5">
              <label for="">Base URL</label>
              <Field
                type="text"
                placeholder="http://bsre.bssn.go.id"
                name="base_url"
                class="form-control bg-transparent"
                v-model="formInput.base_url"
              />
              <ErrorMessage
                name="base_url"
                class="text-danger error-feedback"
              />
            </div>
            <div class="mb-5">
              <label for="">Username</label>
              <Field
                type="text"
                name="auth_username"
                class="form-control bg-transparent"
                v-model="formInput.auth_username"
                autocomplete="off"
              />
              <ErrorMessage
                name="auth_username"
                class="text-danger error-feedback"
              />
            </div>
            <div class="mb-5">
              <label for="">Password</label>
              <Field
                type="password"
                name="auth_password"
                class="form-control bg-transparent"
                v-model="formInput.auth_password"
                autocomplete="off"
              />
              <ErrorMessage
                name="auth_password"
                class="text-danger error-feedback"
              />
            </div>
            <div class="mb-5">
              <label for="">Status</label>
              <Field
                type="text"
                name="status"
                class="form-control bg-transparent"
                v-model="formInput.status"
              />
              <ErrorMessage name="status" class="text-danger error-feedback" />
            </div>

            <div>
              <button
                class="btn btn-secondary"
                @click="$router.push({ name: 'esign-service' })"
              >
                Back
              </button>
              <button
                :disabled="loading"
                type="submit"
                id="kt_sign_in_submit"
                class="btn btn-success"
              >
                <span class="indicator-label">Submit</span>
                <span class="indicator-progress" v-show="loading">
                  Please wait...
                  <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                  >
                  </span>
                </span>
              </button>
            </div>
          </Form>
        </div>
        <!--end::Card body-->
      </div>
      <!--end::Card-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Post-->
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import Service from "../../../services/base.service";

export default {
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      base_url: yup.string().required("base_url is required!"),
      auth_username: yup.string().required("auth_username is required!"),
      auth_password: yup.string().required("auth_password is required!"),
      status: yup.string().required("status is required!"),
    });

    return {
      loading: false,
      message: "",
      formInput: {
        base_url: "",
        auth_username: "",
        auth_password: "",
        status: "",
      },
      schema,
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.fetchData();
    }
    // this.getListData();
  },
  methods: {
    async fetchData() {
      this.loading = true;
      try {
        const BaseService = new Service("esign-service");
        const { data } = await BaseService.getData(this.$route.params.id);
        this.formInput = data;
        // this.message = message;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async handleSubmit(data) {
      this.loading = true;
      const BaseService = new Service("esign-service");
      try {
        const { message } = this.$route.params.id
          ? await BaseService.updateData(this.$route.params.id, data)
          : await BaseService.createData(data);
        this.message = message;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
